import React from 'react';
import Title from '../../../components/Title';
import CheckboxField from '../../../components/CheckboxField';
import { useUserPreferencesContext } from '../UserPreferencesProvider';
import useIsGlobalAdmin from '../../../../../utils/useIsGlobalAdmin';

const PowerBiPreferences: React.FC = () => {
  const { preferences, updatePreferences } = useUserPreferencesContext();
  const powerBiPreferences = preferences.labs.powerBi;
  const isGlobalAdmin = useIsGlobalAdmin();
  return (
    <>
      <Title size="M">PowerBI</Title>
      <CheckboxField
        label="Enable CRA 2.0 User Experience"
        checked={powerBiPreferences.ux2}
        onChange={isChecked =>
          updatePreferences({
            labs: { powerBi: { ...powerBiPreferences, ux2: isChecked } },
          })
        }
      />
      <CheckboxField
        label="Enable Power BI Filter Panel"
        checked={powerBiPreferences.ux2EnableNativeFilterPanel}
        disabled={!powerBiPreferences.ux2}
        onChange={isChecked =>
          updatePreferences({
            labs: { powerBi: { ...powerBiPreferences, ux2EnableNativeFilterPanel: isChecked } },
          })
        }
      />
      <CheckboxField
        label="Enable Power BI Menu"
        checked={powerBiPreferences.ux2EnableActionBar}
        disabled={!powerBiPreferences.ux2}
        onChange={isChecked =>
          updatePreferences({
            labs: { powerBi: { ...powerBiPreferences, ux2EnableActionBar: isChecked } },
          })
        }
      />
      {isGlobalAdmin && (
        <>
          <CheckboxField
            label="Enable PowerBI Report Theme"
            checked={powerBiPreferences.ux2ReportThemeEnabled}
            onChange={isChecked =>
              updatePreferences({
                labs: { powerBi: { ...powerBiPreferences, ux2ReportThemeEnabled: isChecked } },
              })
            }
            disabled={!powerBiPreferences.ux2}
          />
        </>
      )}
      <CheckboxField
        label="Enable Power BI Slicers"
        checked={!powerBiPreferences.ux2HideSlicers}
        onChange={isChecked =>
          updatePreferences({
            labs: { powerBi: { ...powerBiPreferences, ux2HideSlicers: !isChecked } },
          })
        }
        disabled={!powerBiPreferences.ux2}
      />
    </>
  );
};

export default PowerBiPreferences;
